import { Component, OnInit } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DBService } from '../services/db.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
  plansRef: AngularFireList<any>;
  plans: Observable<any[]>;
  //userUID: string;
  constructor(public db: DBService) {
    this.plansRef = db.ConnectPlansRef();
    this.plans = this.plansRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }
  ngOnInit() {
  }

}
