import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DBService {
  goalsRef: AngularFireList<any>;
  tasksRef: AngularFireList<any>;
  plansRef: AngularFireList<any>;
  constructor(public angularFireAuth: AngularFireAuth,
    public db: AngularFireDatabase) {
    let userDBPath = 'users/' + this.angularFireAuth.auth.currentUser.uid;
    this.goalsRef = db.list(userDBPath + '/goals');
    this.tasksRef = db.list(userDBPath + '/tasks');
    this.plansRef = db.list('plans/')
  }

  ConnectGoalsRef(): AngularFireList<any> {
    return this.goalsRef;
  }
  ConnectTasksRef(): AngularFireList<any>{
    return this.tasksRef;
  }
  ConnectPlansRef(): AngularFireList<any>{
    return this.plansRef;
  }
  getUserUID():string{
    return this.angularFireAuth.auth.currentUser.uid;
  }
}
