import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireList } from '@angular/fire/database'
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DBService } from '../services/db.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  goalsRef: AngularFireList<any>;
  tasksRef: AngularFireList<any>;
  goals: Observable<any[]>;
  tasks: Observable<any[]>;
  newGoal: string = '';
  newTask: string = '';
  AllTaskCompleted: boolean = false;
  constructor(public angularFireAuth: AngularFireAuth,
    public dbs: DBService) {    
    // Goals Connection
    this.goalsRef = this.dbs.ConnectGoalsRef();
    this.goals = this.goalsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    // Tasks Connection
    this.tasksRef = this.dbs.ConnectTasksRef();
    this.tasks = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }
  ngOnInit() {
  }
  logout() {
    this.angularFireAuth.auth.signOut()
  }
  addGoal() {
    if (this.newGoal !== '') {
      let now = new Date().getTime();
      this.goalsRef.push({
        title: this.newGoal,
        timestamp: now
      });
      this.newGoal = '';
    }
  }
  deleteGoal(key: string) {
    this.goalsRef.remove(key);
  }
  addTask() {
    if (this.newTask !== '') {
      let now = new Date().getTime();
      this.tasksRef.push({
        title: this.newTask,
        timestamp: now
      });
      this.newTask = '';
    }
  }
  deleteTask(key: string) {
    this.tasksRef.remove(key);
  }
  onDone(key: string, value: boolean) {
    this.tasksRef.update(key, { done: value, doneTimestamp: new Date().getTime() });
  }
  finishDay(){
    
  }
}
