import { Routes } from '@angular/router';

import { HomeComponent } from '@src/app/home/home.component';
import { LandingComponent } from './landing/landing.component';
import { GateComponent } from './gate/gate.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo} from '@angular/fire/auth-guard';
import { PlansComponent } from './plans/plans.component';
import { PlanFormComponent } from './plans/plan-form/plan-form.component';
import { NotFoundComponent} from './not-found/not-found.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['gate']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['dashboard']);

export const routes: Routes = [
  {
      path: '',
      component: LandingComponent,
      canActivate: [AngularFireAuthGuard],
      data: {authGuardPipe: redirectLoggedInToItems}
  },
  {
      path: 'gate',
      component: GateComponent,
      canActivate: [AngularFireAuthGuard],
      data: {authGuardPipe: redirectLoggedInToItems}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin}
  },
  {
    path: 'plans',
    component: PlansComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin}
  },
  {
    path: 'plans/add',
    component: PlanFormComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin}
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];
