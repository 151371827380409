import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth'
import {auth} from 'firebase/app';
import { Router } from '@angular/router';
@Component({
  selector: 'app-gate',
  templateUrl: './gate.component.html',
  styleUrls: ['./gate.component.scss']
})
export class GateComponent implements OnInit {

  constructor(public angularFireAuth: AngularFireAuth, public router: Router) { }

  ngOnInit() {
    
  }

  login(){
    this.angularFireAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then((user)=>{
      if(user){
        this.router.navigateByUrl('/dashboard');
      }
    });
  }
  logout(){
    this.angularFireAuth.auth.signOut();
  }
}
