import { Component, OnInit, Input } from '@angular/core';
import { AngularFireList } from '@angular/fire/database';
import { FormGroup, FormControl, FormArray, RequiredValidator, Validators } from '@angular/forms';
import { DBService } from '@src/app/services/db.service';

@Component({
  selector: 'app-plan-form',
  templateUrl: './plan-form.component.html',
  styleUrls: ['./plan-form.component.scss']
})
export class PlanFormComponent implements OnInit {
  //@Input() plansRef: AngularFireList<any>
  planForm = new FormGroup({
    title: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    price: new FormControl(''),
    tasks: new FormArray([new FormControl('', Validators.required)])
  });
  tasks = this.planForm.get('tasks') as FormArray;
  plansRef: AngularFireList<any>;
  constructor(public db: DBService) { 
    this.plansRef = this.db.ConnectPlansRef();
  }

  ngOnInit() {
  }
  addTask(){
    if(this.tasks.valid=== false) return;
    this.tasks.push(new FormControl('', Validators.required));
  }
  deleteTask(i:number){
    if (i === 0) return;
    this.tasks.removeAt(i);
  }
  onSubmit(){
    this.plansRef.push({
       title: this.planForm.get('title').value,
       detail: this.planForm.get('description').value,
       timestamp: new Date().getTime(),
       tasks: this.planForm.get('tasks').value,
       author: this.db.getUserUID()
    }).then(
      ()=>{
        this.planForm.reset();
      }
    );
  }

}
