import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'

import { AppRoutingModule } from '@src/app/app-routing.module';
import { AppComponent } from '@src/app/app.component';
import { HomeComponent } from '@src/app/home/home.component';
import { LandingComponent } from '@src/app/landing/landing.component';
import { DashboardComponent } from '@src/app/dashboard/dashboard.component';
import { GateComponent } from '@src/app/gate/gate.component';
import {AngularFireAuthModule} from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '@src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AngularFireAuthGuard} from '@angular/fire/auth-guard';
import { GoalFormComponent } from '@src/app/goal-form/goal-form.component'
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { PlansComponent } from '@src/app/plans/plans.component';
import { PlanFormComponent } from '@src/app/plans/plan-form/plan-form.component';
import { NotFoundComponent } from '@src/app/not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LandingComponent,
    DashboardComponent,
    GateComponent,
    GoalFormComponent,
    PlansComponent,
    PlanFormComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AngularFireAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
